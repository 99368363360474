import { Facet, Facets } from '@wam/shared';

export class AnalyticsQuery {
  private readonly query: string;

  constructor(query: string) {
    this.query = query;
  }

  static fromFacets(facets: Facets): AnalyticsQuery {
    const body = Object.keys(facets)
      .map((key) => this.getParams(key, facets))
      .filter(Boolean)
      .join('&');
    return new AnalyticsQuery(body);
  }

  private static getParams(key, facets: Facets) {
    const paramValue = facets[key]
      .map((facet: Facet) => (facet.ignore ? '' : this.getValue(key, facet)))
      .join(',');

    if (paramValue !== '') {
      return `${key}=${paramValue}`;
    }
    return '';
  }

  private static getValue(key: string, facet: Facet) {
    let facetValue: string = facet.value;
    if (key === 'classes' && facetValue.includes('////')) {
      facetValue = facetValue.split('////')[1];
    }
    return encodeURIComponent(facetValue);
  }

  toString(): string {
    return this.query;
  }
}
